import React from "react";
import "./experience.css";
import { BsPatchCheckFill } from "react-icons/bs";

const Experience = () => {
  return (
    <section id="experience">
      <h5>What Skils I have</h5>
      <h2>Me Experience</h2>

      <div className="container experience__container">
        <div className="experience__level">
          <h3>Level definition</h3>
          <div className="experience__content">
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="experience__details-description">
                <h4>level 1</h4>
                <small className="text-light">Beginer</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="experience__details-description">
                <h4>level 2</h4>
                <small className="text-light">Can use with some help</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="experience__details-description">
                <h4>level 3</h4>
                <small className="text-light">
                  Can use without anyones help
                </small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="experience__details-description">
                <h4>level 4</h4>
                <small className="text-light">Can use very well</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="experience__details-description">
                <h4>level 5</h4>
                <small className="text-light">Can teach someone</small>
              </div>
            </article>
          </div>
        </div>
        {/* END LEVEL EXPLINATION */}
        <div className="experience__frontend">
          <h3>Frontend Development</h3>
          <div className="experience__content">
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="experience__details-description">
                <h4>HTML5</h4>
                <small className="text-light">level 5</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="experience__details-description">
                <h4>CSS3 / SASS</h4>
                <small className="text-light">level 4</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="experience__details-description">
                <h4>JS</h4>
                <small className="text-light">level 5</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="experience__details-description">
                <h4>Bootstrap</h4>
                <small className="text-light">level 5</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="experience__details-description">
                <h4>React JS[16.3]</h4>
                <small className="text-light">level 4</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="experience__details-description">
                <h4>Next JS</h4>
                <small className="text-light">level 3</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="experience__details-description">
                <h4>React Native</h4>
                <small className="text-light">level 1</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="experience__details-description">
                <h4>Redux</h4>
                <small className="text-light">level 3</small>
              </div>
            </article>
          </div>
        </div>

        {/* END OF FRONTEND */}

        <div className="experience__backend">
          <h3>Backend Development</h3>
          <div className="experience__content">
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="experience__details-description">
                <h4>Node JS</h4>
                <small className="text-light">level 4</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="experience__details-description">
                <h4>Mongo DB</h4>
                <small className="text-light">level 3</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="experience__details-description">
                <h4>PHP</h4>
                <small className="text-light">level 3</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="experience__details-description">
                <h4>SQL/NoSQL</h4>
                <small className="text-light">level 3</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="experience__details-description">
                <h4>Python</h4>
                <small className="text-light">level 3</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="experience__details-description">
                <h4>Rest API</h4>
                <small className="text-light">level 3</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="experience__details-description">
                <h4>Ruby</h4>
                <small className="text-light">level 3</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="experience__details-description">
                <h4>Express</h4>
                <small className="text-light">level 3</small>
              </div>
            </article>
          </div>
        </div>
        {/* END OF BACK END */}
        <div className="experience__testing">
          <h3>Testing</h3>
          <div className="experience__content">
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="experience__details-description">
                <h4>JMeter</h4>
                <small className="text-light">level 3</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="experience__details-description">
                <h4>Selenium testing</h4>
                <small className="text-light">level 3</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="experience__details-description">
                <h4>Selenium testing</h4>
                <small className="text-light">level 3</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="experience__details-description">
                <h4>Postman API testing</h4>
                <small className="text-light">level 3</small>
              </div>
            </article>
          </div>
        </div>
        {/* END OF TEST END */}
      </div>
    </section>
  );
};

export default Experience;
