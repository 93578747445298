import React from "react";
import "./services.css";
import { BiCheck } from "react-icons/bi";

const Services = () => {
  return (
    <section id="services">
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services_container">
        <article className="service">
          <div className="service__head">
            <h3>UI/UX Design</h3>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>User research</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Draw prototype</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Testing and iteration</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Information architecture </p>
            </li>
          </ul>
        </article>

        {/* END OF UI/UX */}
        <article className="service">
          <div className="service__head">
            <h3>Development</h3>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>Any size of static web development</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Any size of dynamic web development</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Trading platforms development</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Online shops development</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Online library development</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Medium size android apps</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Wordpress web development</p>
            </li>
          </ul>
        </article>

        {/* END OF Web Development */}
        <article className="service">
          <div className="service__head">
            <h3>Others</h3>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>Web application project advice</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Stress testing</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Scenario testing for web application</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>AWS cloud migration from local server</p>
            </li>
          </ul>
        </article>

        {/* END OF Content Creation*/}
      </div>
    </section>
  );
};

export default Services;
