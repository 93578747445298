import React from "react";
import "./portfolio.css";
import IMG1 from "../../assets/portfolio1.jpg";
import IMG2 from "../../assets/portfolio2.jpg";
import IMG3 from "../../assets/portfolio3.jpg";
import IMG4 from "../../assets/portfolio4.jpg";
import IMG5 from "../../assets/portfolio5.png";
import IMG6 from "../../assets/portfolio6.jpg";

const data = [
  {
    id: 1,
    image: IMG1,
    title: "Online currency exchange platform",
    github: false,
    demo: "https://moex.mn",
  },
  {
    id: 2,
    image: IMG2,
    title: "Personal portfolio website",
    github: false,
    demo: false,
  },
  {
    id: 3,
    image: IMG3,
    title: "Company portfolio website",
    github: false,
    demo: false,
  },
  {
    id: 4,
    image: IMG4,
    title: "Online book shop",
    github: false,
    demo: "https://elib.mn",
  },
  {
    id: 5,
    image: IMG5,
    title: "Currency crawler cron job",
    github: false,
    demo: false,
  },
  {
    id: 6,
    image: IMG6,
    title: "Online ticket website on wordpress",
    github: false,
    demo: false,
  },
];
const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {data.map(({ id, image, title, github, demo }) => {
          return (
            <article key={id} className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
                {github !== false && (
                  <a href={github} className="btn">
                    Github
                  </a>
                )}
                {demo !== false && (
                  <a
                    href={demo}
                    className="btn btn-primary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Live Demo
                  </a>
                )}
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;
